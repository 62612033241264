@import '../../../../scss/theme-bootstrap';

.header-offers-banner-item {
  overflow: hidden;
  width: 100%;
  @media #{$large-up} {
    overflow: unset;
  }
  &__offer-text {
    display: inline;
    p {
      display: inline;
      line-height: inherit;
      margin: 0;
    }
  }
  a {
    .header-black-white &,
    .header-black-light-transparent & .header-black-dark-transparent & {
      @include text-link--style-5;
    }
    .header-white-white &,
    .header-white-light-transparent & .header-white-dark-transparent & {
      @include text-link--style-2;
    }
  }
}
